import axiosInstanceApiGateway from 'config/apiService'
import { setTokenCookies } from 'utils/cookie'
import { polylineDecode } from 'utils/polylineDecode'
import { uppercaseFirstLetter } from 'utils/text'
import { toastErrResponse } from 'utils/toast'

type UserData = {
  userId: number
  username: string
  auth: string
  location: {
    id: number
    latitude: number
    longitude: number
  }
}

type DriverAndOrderPosition = {
  id: number
  driver_name: string
  driver_status: string
  has_order: boolean
  lat: number
  lon: number
  timestamp: number
}

export type DriverType = {
  driver_id: number
  driver_name: string
  driver_status: string
  driver_back_to_hub: number
  order_delivery_at: number
  orders: {
    order_id: number
    order_customer_name: string
    order_customer_address: string
    order_customer_address_position: {
      latitude: number
      longitude: number
    }
    order_status: string
    order_progress: string
  }[]
  activities: {
    name: string
    image_url: string
    note: string
    created_at: number
    order_id: string
    order_service_level_name: string
    order_status: string
    duration: string
    status: boolean
  }[]
}

type FilterStatus =
  | 'SHOW_ALL_DRIVER'
  | 'FILTER_AVAILABLE'
  | 'FILTER_ON_DELIVERY'
  | 'FILTER_BACK_TO_HUB'

type PostGetRoutePayload = {
  locations: {
    lat: number
    lon: number
  }[]
  units: string
  costing: string
  id?: string
}

type PostGetRouteResponse = {
  data: {
    id: string
    trip: {
      detail: {
        shape: string
        summary: {
          distance: number
          duration: number
        }
      }[]
      locations: {
        lat: number
        lon: number
      }
    }
  }
  error: {
    code: number
    message: string
    status: boolean
  }
}

export type GetFeatureFlagRoutingEngineResponse = {
  data: {
    app_version: string | null
    device: number
    features: {
      driver_routing_engine: boolean
    }
    location_id: number
  }
  error: {
    code: number
    message: string
    status: boolean
  }
}

export type LiveTrackingDriverSlice = {
  isLoading: boolean
  userData: UserData | null
  filter: FilterStatus
  driverPosition: {
    [id: string]: DriverAndOrderPosition
  }
  listDriverActive: DriverType[]
  driverSelected: DriverType | null
  driverRoute: [number, number][]
  error: {
    [id: number]: boolean
  }
  setIsLoading: (isLoading: boolean) => void
  setFilter: (
    status: FilterStatus,
    callback?: (drivers: DriverAndOrderPosition[]) => void
  ) => void
  setUserData: (payload: UserData) => void
  setDriverPosition: (
    payload: DriverAndOrderPosition,
    callback: (
      driverHasAdded: boolean,
      allDriverPosition: [number, number][]
    ) => void
  ) => void
  setDriverSelected: (payload: DriverType | null, callback?: () => void) => void
  setUpdateDriver: (payload: DriverType[]) => void
  setError: (payload: { id: number; isError: boolean }) => void
  setRoute: (payload: [number, number][]) => void
  postGetRoute: (payload: PostGetRoutePayload) => Promise<[number, number][]>
}

export const createLiveTrackingDriverSlice: StoreSlice<
  LiveTrackingDriverSlice
> = (set, get) => ({
  isLoading: true,
  userData: null,
  filter: 'SHOW_ALL_DRIVER',
  driverPosition: {},
  listDriverActive: [],
  driverSelected: null,
  driverRoute: [],
  error: {},
  setIsLoading: (isLoading) => {
    set((state) => {
      state.isLoading = isLoading
    })
  },
  setFilter: (status, callback) => {
    set((state) => {
      state.filter = state.filter !== status ? status : 'SHOW_ALL_DRIVER'
      state.driverSelected = null
    })

    const { driverPosition } = get()
    const drivers = Object.values(driverPosition)

    if (callback) callback(drivers)
  },
  setUserData: (payload) => {
    set((state) => {
      state.userData = payload
    })
    setTokenCookies(payload.auth)
  },
  setDriverPosition: (payload, callback) => {
    const { id } = payload
    const { driverPosition } = get()

    const allDriverPosition: [number, number][] = []

    Object.values(driverPosition).forEach((data) => {
      const loc = [data.lat, data.lon] as [number, number]
      allDriverPosition.push(loc)
    })
    allDriverPosition.push([payload.lat, payload.lon])

    set((state) => {
      state.driverPosition[id] = {
        ...payload,
        driver_status:
          state.driverPosition?.[id]?.driver_status ||
          uppercaseFirstLetter(payload.driver_status),
      }
      state.error[id] = false
    })

    if (callback) callback(!!driverPosition[id], allDriverPosition)
  },
  setDriverSelected: (payload, callback) => {
    set((state) => {
      state.driverRoute = payload ? state.driverRoute : []
      state.driverSelected = payload
    })

    if (callback) callback()
  },
  setUpdateDriver: (payload) => {
    const { driverPosition, driverSelected } = get()

    set((state) => {
      state.listDriverActive = payload
    })

    Object.values(driverPosition).forEach((values) => {
      payload.forEach((item) => {
        if (item.driver_id === values.id) {
          set((state) => {
            state.driverPosition[item.driver_id].driver_status =
              item.driver_status
          })
        }

        if (driverSelected?.driver_id === item.driver_id) {
          set((state) => {
            state.driverSelected = item
          })
        }
      })
    })
  },
  setError: (payload) => {
    const { id, isError } = payload
    set((state) => {
      state.error[id] = isError
    })
  },
  setRoute: (payload: [number, number][]) => {
    set((state) => {
      state.driverRoute = payload
    })
  },
  postGetRoute: async (payload) => {
    try {
      const response = await axiosInstanceApiGateway.post<PostGetRouteResponse>(
        '/location/internal/v1/route',
        payload
      )

      const shapeEncoded = response.data.data.trip.detail?.[0]?.shape
      if (shapeEncoded) {
        const route = polylineDecode(shapeEncoded)

        return route
      }

      return []
    } catch (err) {
      toastErrResponse(err, 'Gagal mendapatkan rute')
      throw err
    }
  },
})
