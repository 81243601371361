import { ActionPostMessageEnum } from 'constants/postMessage'
import useReceiveMessage from 'hooks/useReceiveMessage'
import { LatLngTuple } from 'leaflet'
import { useEffect, useMemo, useState } from 'react'
import { usePoolingMapStore } from 'store/poolingMap'
import { polylineDecode } from 'utils/polylineDecode'
import { DeliveriesType, ListDeliveriesType } from './type'

const usePoolingMap = () => {
  const {
    selectedOrder,
    setReset,
    setHasPooling,
    allDeliveryOrder,
    setAllDeliveryOrder,
    setSelectedOrder,
  } = usePoolingMapStore()
  const [hubLocation, setHubLocation] = useState<LatLngTuple>([
    -6.175392, 106.827153,
  ])
  const [mapCenter, setMapCenter] = useState<LatLngTuple>([
    -6.175392, 106.827153,
  ])
  const [mapZoom, setMapZoom] = useState(14)
  const [routes, setRoutes] = useState<[number, number][]>([])

  useReceiveMessage<ListDeliveriesType>(
    ActionPostMessageEnum.SHOW_ALL_ORDERS,
    (data) => {
      if (data?.payload?.delivery_pools) {
        const deliveryPools = data?.payload?.delivery_pools
        const allDeliveries = deliveryPools?.flatMap((pool) =>
          pool.deliveries?.map((delivery) => ({
            ...delivery,
            pool_id: pool.pool_id,
            color: pool.color,
            pool_number: pool.pool_number,
          }))
        ) as DeliveriesType
        setAllDeliveryOrder(allDeliveries)
      }
    }
  )

  useReceiveMessage<number>(ActionPostMessageEnum.FLY_TO_ORDER, (data) => {
    const poolId = data.payload
    const order = allDeliveryOrder.find(
      (delivery) => delivery.pool_id === poolId
    )
    if (order) {
      setMapCenter([
        order.destination.address.latitude,
        order.destination.address.longitude,
      ])
    }
  })
  useReceiveMessage<string>(ActionPostMessageEnum.RESET_ORDER, () => {
    setReset()
    setRoutes([])
  })

  useReceiveMessage<string>(ActionPostMessageEnum.HAS_POOLING, (data) => {
    setHasPooling(data.payload)
  })
  useReceiveMessage<{ shapes: string[]; total_distance: number }>(
    ActionPostMessageEnum.POST_NEW_ROUTE,
    (data) => {
      const routesDecoded = data.payload.shapes.map((route) =>
        polylineDecode(route)
      )
      const flattenedRoutes = routesDecoded.flat()
      setRoutes(flattenedRoutes)
    }
  )

  useReceiveMessage<{
    locationSelected: { location_latitude: string; location_longitude: string }
  }>(ActionPostMessageEnum.SET_HUB_LOCATION, (data) => {
    // eslint-disable-next-line camelcase
    const { location_latitude, location_longitude } =
      data.payload.locationSelected
    setHubLocation([Number(location_latitude), Number(location_longitude)])
  })

  useReceiveMessage<DeliveriesType[number]>(
    ActionPostMessageEnum.SELECT_POOL,
    (data) => {
      setSelectedOrder(data.payload)
    }
  )

  const allPositions = useMemo(
    () =>
      [
        hubLocation,
        ...allDeliveryOrder.map(
          (delivery) =>
            [
              delivery?.destination?.address?.latitude,
              delivery?.destination?.address?.longitude,
            ] as LatLngTuple
        ),
      ].filter((pos) => pos[0] !== 0 && pos[1] !== 0),
    [hubLocation, allDeliveryOrder]
  )

  useEffect(() => {
    if (allPositions.length > 0) {
      const gridSize = 0.0001
      const clusters: Record<string, number> = {}

      allPositions.forEach((pos) => {
        const key = `${Math.floor(pos[0] / gridSize)},${Math.floor(
          pos[1] / gridSize
        )}`
        clusters[key] = (clusters[key] || 0) + 1
      })

      const largestCluster = Object.entries(
        clusters as Record<string, number>
      ).reduce(
        (max, [key, count]) => (count > max[1] ? [key, count] : max),
        ['', 0]
      )

      const [lat, lng] = largestCluster[0]
        .split(',')
        .map((n) => parseFloat(n) * gridSize)

      setMapCenter([lat + gridSize / 2, lng + gridSize / 2])

      const zoomLevels = [16, 15, 14, 13, 12]
      const clusterSizeThresholds = [5, 10, 20, 30, Infinity]
      const zoom =
        zoomLevels[
          clusterSizeThresholds.findIndex(
            (threshold) => largestCluster[1] <= threshold
          )
        ]

      setMapZoom(zoom)
    }
  }, [allPositions])
  return {
    mapCenter,
    mapZoom,
    hubLocation,
    allDeliveryOrder,
    selectedOrder,
    routes,
  }
}

export default usePoolingMap
