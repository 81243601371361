import { Polyline, useMap } from 'react-leaflet'
import { memo, useEffect, useRef, useState } from 'react'
import L, { LatLngTuple } from 'leaflet'
import {
  LeafletTrackingMarker,
  LeafletTrackingMarkerElement,
} from 'react-leaflet-tracking-marker'
import { driverIcon } from '../Icon'
import { DriverPopup } from '../Popup'
import { isMoreThanTwoMinutes } from '../utils'

type PositionType = [number, number] | LatLngTuple

type DriverTrackingMarkerPropsType = {
  driverPosition: PositionType
  driverId: number
  driverName: string
  driverStatus: string
  latestUpdate: number
  duration?: number
  focusOnDriverAndDestination?: boolean
  destinationPosition?: PositionType
  showPolyline?: boolean
  isError?: boolean
  onError?: (driverId: number, isError: boolean) => void
  onClick?: (driverId: number, driverStatus: string) => void
}

let autoZoomTimeout: NodeJS.Timeout

const DriverTrackingMarker = memo(
  ({
    driverPosition,
    driverId,
    driverName,
    driverStatus,
    duration = 15000,
    latestUpdate,
    destinationPosition,
    focusOnDriverAndDestination,
    showPolyline,
    isError = false,
    onError,
    onClick,
  }: DriverTrackingMarkerPropsType) => {
    const map = useMap()
    const markerRef = useRef<LeafletTrackingMarkerElement | null>(null)

    const [position, setPosition] = useState<PositionType>(driverPosition)
    const [prevPos, setPrevPos] = useState<PositionType>(driverPosition)
    const [nextPos, setNextPos] = useState<PositionType>(driverPosition)
    const [updateCount, setUpdateCount] = useState(0)
    const [manualInteraction, setManualInteraction] = useState(false)
    const [zoom, setZoom] = useState(map.getZoom())
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)
    const [moveDuration, setMoveDuration] = useState(duration)

    const autoFitBoundsAndSetZoom = (currentTrack: PositionType) => {
      if (
        !manualInteraction &&
        focusOnDriverAndDestination &&
        destinationPosition
      ) {
        const bounds = new L.LatLngBounds([currentTrack, destinationPosition])
        map.fitBounds(bounds, { padding: [50, 50] })
      }
    }

    useEffect(() => {
      if (markerRef.current) {
        markerRef.current.closePopup = () =>
          null as unknown as LeafletTrackingMarkerElement
        markerRef.current.openPopup()
      }
    }, [])

    useEffect(() => {
      if (updateCount >= 2) {
        setPosition(nextPos)
      }
      setPrevPos(position)
      setNextPos(driverPosition)
      setUpdateCount((prev) => prev + 1)

      const newDuration = !isMoreThanTwoMinutes(latestUpdate) ? duration : 1

      setMoveDuration(newDuration)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [driverPosition])

    useEffect(() => {
      if (!manualInteraction) {
        autoFitBoundsAndSetZoom(prevPos)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position])

    useEffect(() => {
      const handleZoomStart = () => {
        setManualInteraction(true)
        clearTimeout(autoZoomTimeout)
      }

      const handleZoomEnd = (e: L.LeafletEvent) => {
        // eslint-disable-next-line no-underscore-dangle
        setZoom(e.target._zoom)
        autoZoomTimeout = setTimeout(() => {
          setManualInteraction(false)
        }, 10000)
      }

      const handleDragStart = () => {
        setManualInteraction(true)
        clearTimeout(autoZoomTimeout)
      }

      const handleDragEnd = () => {
        autoZoomTimeout = setTimeout(() => {
          setManualInteraction(false)
        }, 10000)
      }

      map.on('zoomstart', handleZoomStart)
      map.on('zoomend', handleZoomEnd)
      map.on('dragstart', handleDragStart)
      map.on('dragend', handleDragEnd)

      autoFitBoundsAndSetZoom(driverPosition)

      return () => {
        map.off('zoomstart', handleZoomStart)
        map.off('zoomend', handleZoomEnd)
        map.off('dragstart', handleDragStart)
        map.off('dragend', handleDragEnd)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (latestUpdate) {
        if (timeoutId) {
          clearTimeout(timeoutId)
        }

        const newTimeoutId = setTimeout(() => {
          if (onError) {
            onError(driverId, true)
          }
        }, 2 * 60 * 1000)

        setTimeoutId(newTimeoutId)
      }

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latestUpdate, onError, driverId])

    useEffect(() => {
      const handleMarkerClick = () => {
        if (onClick) onClick(driverId, driverStatus)
      }

      if (markerRef.current) {
        const markerElement = markerRef.current.getElement()
        if (markerElement) {
          markerElement.addEventListener('click', handleMarkerClick)
        }
      }

      return () => {
        if (markerRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          const markerElement = markerRef.current.getElement()
          if (markerElement) {
            markerElement.removeEventListener('click', handleMarkerClick)
          }
        }
      }
    }, [driverId, driverStatus, onClick])

    return (
      <LeafletTrackingMarker
        icon={driverIcon(driverStatus, zoom)}
        position={position}
        previousPosition={prevPos}
        duration={moveDuration}
        ref={markerRef}
        zIndexOffset={80000}
      >
        <DriverPopup
          driverName={driverName}
          isError={isError}
          latestUpdate={latestUpdate}
          zoom={zoom}
        />
        {showPolyline && destinationPosition && (
          <Polyline
            positions={[position, destinationPosition]}
            color="#246EE5"
          />
        )}
      </LeafletTrackingMarker>
    )
  }
)

export default DriverTrackingMarker
