import axios from 'axios'
import Cookies from 'js-cookie'
import { getNativeHeaders } from 'utils/cookie'

import { queryParamsURL } from 'utils/queryParamsURL'
import { axiosErrorLoggerInterceptor } from './errorLog'

const axiosInstanceApiGateway = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_KONG_API,
})

axiosInstanceApiGateway.interceptors.request.use(
  (config) => {
    const { authToken } = queryParamsURL.get()
    const nativeHeaders = getNativeHeaders()

    const credentials =
      authToken || nativeHeaders?.Authorization || Cookies.get('_au')

    if (config.headers) {
      config.headers['Content-Type'] = 'application/json'
      config.headers.Authorization = `Bearer ${credentials}`
    }

    if (credentials) {
      Cookies.set('_au', credentials)
      queryParamsURL.remove(['authToken'])
    }

    return config
  },
  (error) => {
    if (error?.request?.status === 401) {
      Cookies.remove('_au')
      window.location.href = '/'
    }
    return Promise.reject(error)
  }
)

axiosInstanceApiGateway.interceptors.response.use(
  (response) => response,
  (error) => {
    axiosErrorLoggerInterceptor(error)

    return Promise.reject(error)
  }
)

export default axiosInstanceApiGateway
