import PoolMap from 'components/PoolMap'
import CustomMarkerIcon from 'components/PoolMap/Marker/CustomMarkerIcon'
import FlyMapTo from 'components/TrackingMap/FlyMapTo'
import { Marker, Polyline } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { hubIcon } from 'components/TrackingMap'
import usePoolingMap from './usePoolingMap'

const PoolingMap = () => {
  const {
    mapCenter,
    mapZoom,
    hubLocation,
    allDeliveryOrder,
    selectedOrder,
    routes,
  } = usePoolingMap()
  return (
    <PoolMap center={mapCenter} zoom={mapZoom}>
      <FlyMapTo position={mapCenter} zoom={12} />
      <Marker icon={hubIcon} position={hubLocation} />
      <MarkerClusterGroup
        maxClusterRadius={10}
        zoomToBoundsOnClick={false}
        spiderfyOnMaxZoon={false}
        spiderfyDistanceMultiplier={3}
        chunkedLoading
      >
        {allDeliveryOrder
          .filter((delivery) => {
            if (selectedOrder.length > 0) {
              const poolNumbers = selectedOrder.map(
                (order) => order.pool_number
              )
              poolNumbers.push('0')
              return poolNumbers.includes(delivery.pool_number)
            }
            return true
          })
          .map((delivery, i) => (
            <CustomMarkerIcon
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              position={[
                delivery?.destination?.address?.latitude,
                delivery?.destination?.address?.longitude,
              ]}
              delivery={delivery}
            />
          ))}
      </MarkerClusterGroup>
      <Polyline
        positions={routes}
        pathOptions={{ color: '#0f53ff', weight: 6, opacity: 1 }}
      />
    </PoolMap>
  )
}

export default PoolingMap
