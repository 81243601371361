/* eslint-disable no-console */
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js'
import { Wrapper } from '@googlemaps/react-wrapper'
import 'leaflet/dist/leaflet.css'
import { IClientOptions } from 'mqtt'
import { useEffect } from 'react'
import { MapContainer, MapContainerProps } from 'react-leaflet'
import { useMqttStore } from 'store/mqtt'
import { toastWebview } from 'components/ToastWebview'
import { log } from '@astronautsid/wpe-utils'
import TileLayer from './TileLayer'

export type TrackingMapType = {
  children: React.ReactNode
  mqttUsername?: string
  mqttPassword?: string
  hubId?: string | number
  orderId?: string | number
} & MapContainerProps

const mqttOptions: IClientOptions = {
  keepalive: 60,
  protocolId: 'MQTT',
  protocolVersion: 5,
  clean: true,
  reconnectPeriod: 1000,
  connectTimeout: 30 * 1000,
}

const TrackingMap = ({
  children,
  mqttUsername,
  mqttPassword,
  zoom = 19,
  dragging = true,
  hubId,
  orderId,
  ...props
}: TrackingMapType) => {
  const { client, mqttConnect, setConnectStatus } = useMqttStore()

  useEffect(() => {
    const host = process.env.REACT_APP_MQTT_URL || ''
    const connectToMqtt = async () => {
      if (mqttUsername && mqttPassword) {
        const fingerprint = await getCurrentBrowserFingerPrint()

        mqttConnect(host, {
          clientId: fingerprint,
          username: mqttUsername,
          password: mqttPassword,
          ...mqttOptions,
        })
      }
    }
    connectToMqtt()
  }, [mqttConnect, mqttPassword, mqttUsername])

  useEffect(() => {
    if (client) {
      client.on('connect', () => {
        setConnectStatus('Connected')
        console.log('Connection Successful')
      })

      client.on('error', (err) => {
        toastWebview({
          message: `Connection Error: ${err}`,
        })
        log.error(err?.toString(), {
          customInfo: {
            origin: 'mqtt',
            data: {
              username: mqttUsername,
              hubId,
              orderId,
            },
          },
        })
        client.end()
      })

      client.on('reconnect', () => {
        console.error('Reconnection...')
        setConnectStatus('Reconnecting')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, setConnectStatus])

  return (
    <Wrapper apiKey={process.env.REACT_APP_MAPS_KEY || ''}>
      <MapContainer
        id="mapId"
        zoom={zoom}
        dragging={dragging}
        style={{
          width: '100vw',
          height: '100vh',
          position: 'fixed',
        }}
        {...props}
      >
        <TileLayer />
        {children}
      </MapContainer>
    </Wrapper>
  )
}

export default TrackingMap
