/* eslint-disable no-console */
import { log } from '@astronautsid/wpe-utils'
import { toastWebview } from 'components/ToastWebview'
import mqtt, { IClientOptions, IClientSubscribeOptions, MqttClient } from 'mqtt'

export type SubcribtionType = {
  username: string
  topic: string
  qos: IClientSubscribeOptions['qos']
}

export type MqttSlice = {
  connectStatus: 'Connect' | 'Connecting' | 'Connected' | 'Reconnecting'
  client: MqttClient | null
  isSubcribed: boolean
  mqttConnect: (host: string, mqttOption: IClientOptions) => void
  mqttDisconnect: () => void
  mqttSubcribe: (subcribtion: SubcribtionType) => void
  mqttUnSubcribe: (subcribtion: SubcribtionType) => void
  setConnectStatus: (status: MqttSlice['connectStatus']) => void
}

export const createMqttSlice: StoreSlice<MqttSlice> = (set, get) => ({
  connectStatus: 'Connect',
  client: null,
  isSubcribed: false,
  mqttConnect: (host, mqttOption) => {
    set((state) => {
      state.connectStatus = 'Connecting'
      state.client = mqtt.connect(host, mqttOption)
    })
  },
  mqttDisconnect: () => {
    const { client } = get()

    if (client) {
      try {
        client.end(false, () => {
          set((state) => {
            state.connectStatus = 'Connect'
          })

          console.log('disconnected successfully')
        })
      } catch (error) {
        console.log('disconnect error:', error)
      }
    }
  },
  mqttSubcribe: (subscription) => {
    const { client } = get()

    if (client) {
      const { username, topic, qos } = subscription

      client.subscribe(topic, { qos }, (error) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          toastWebview({
            message: error.message,
          })
          log.error(error.message, {
            customInfo: {
              origin: 'mqtt',
              data: {
                username,
                topic,
              },
            },
          })
          return
        }
        console.log(`Subscribe to topics: ${topic}`)

        set((state) => {
          state.isSubcribed = true
        })
      })
    }
  },
  mqttUnSubcribe: (subscription) => {
    const { client } = get()

    if (client) {
      const { topic } = subscription
      client.unsubscribe(topic, {}, (error) => {
        if (error) {
          console.log('Unsubscribe error', error)
          return
        }
        console.log(`unsubscribed topic: ${topic}`)

        set((state) => {
          state.isSubcribed = false
        })
      })
    }
  },
  setConnectStatus: (status) => {
    set((state) => {
      state.connectStatus = status
    })
  },
})
